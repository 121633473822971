<template>
  <div class="animated fadeIn">
    <b-form @submit.stop.prevent="onSubmit">
      <b-row>
        <b-col sm="6" offset-sm="3" xs="12">
          <b-card>
            <div slot="header">
              <strong>
                <i class="fa fa-user-circle" />
                {{ $t("userProfile") }}</strong
              >
            </div>
            <b-row>
              <b-col sm="6">
                <b-col sm="12">
                  <ValidationableTextBox
                    prename="user"
                    id="firstName"
                    icon="fa fa-user"
                    v-model="$v.form.firstName.$model"
                    :form="$v.form.firstName"
                  />
                </b-col>

                <b-col sm="12">
                  <ValidationableTextBox
                    prename="user"
                    id="lastName"
                    icon="fa fa-user"
                    v-model="$v.form.lastName.$model"
                    :form="$v.form.lastName"
                  />
                </b-col>
              </b-col>
              <b-col sm="6">
                <b-col sm="12">
                  <ValidationableTextBox
                    prename="user"
                    id="email"
                    icon="fa fa-envelope"
                    v-model="$v.form.email.$model"
                    :form="$v.form.email"
                  />
                </b-col>
                <b-col sm="12">
                  <ValidationableTextBox
                    prename="user"
                    icon="fa fa-user"
                    id="userName"
                    v-model="$v.form.userName.$model"
                    :form="$v.form.userName"
                  />
                </b-col>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" class="text-right">
                <div class="form-actions">
                  <BrandButton
                    buttonVariant="success"
                    buttonIcon="fa fa-save"
                    @click="save"
                    :text="$t('save')"
                    v-if="$auth.hasAuth($options, 'save')"
                  />

                  <BrandButton
                    buttonVariant="danger"
                    buttonIcon="fa fa-undo"
                    @click="back"
                    :text="$t('back')"
                  />
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import http from "../../shared/http";
import userValidator from "../../validations/user-validator";
import toast from "../../shared/toast-helper";
import ValidationableTextBox from "../../widgets/form/ValidationableTextBox";
import CheckBox from "../../widgets/form/CustomCheckBox";
import BrandButton from "../../widgets/form/CustomBrandButton";
import { validationMixin } from "vuelidate";

export default {
  name: "UserProfile",
  components: {
    ValidationableTextBox,
    CheckBox,
    BrandButton,
  },
  mixins: [validationMixin],
  validations: userValidator.getValidations(false),
  methods: {
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      let model = { ...this.user, ...this.form };
      let path = model.id > 0 ? "users/updatecurrentuser" : "users/add";
      http.post(path, model).then((result) => {
        if (result && result.success) {
          toast.success(result.message);
          http.get(`Auth/refreshtoken`).then((response) => {
            localStorage.token = response.token;
            this.$router.push({ path: "/dashboard" });
          });
        }
      });
    },
    back() {
      this.$router.push({ path: "/dashboard" });
    },
    getUpdateForm(userId) {
      let path = `users/getcurrentuser`;
      http.get(path).then((user) => {
        if (user) {
          this.user = user;
          this.form.firstName = user.firstName;
          this.form.lastName = user.lastName;
          this.form.email = user.email;
          this.form.userName = user.userName;
          this.form.isUpdating = true;
          this.validations = userValidator.getValidations();
        }
      });
    },
  },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        isUpdating: false,
      },
      user: {
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
      },
    };
  },
  created() {
    this.getUpdateForm();
  },
};
</script>
